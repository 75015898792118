import React, { useRef, useState } from "react";
import { css } from "@emotion/react";
import { useFormContext, useWatch } from "react-hook-form";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import { FullScreenModalDialog } from "main/javascripts/components/molecules/Modal/FullScreenModalDialog";
import { ScrollableDatePicker } from "main/javascripts/components/molecules/DatePicker";
import { isPhone } from "main/javascripts/styles/base/responsiveStyle";
import { HorizontalDatePicker } from "main/javascripts/components/molecules/DatePicker/HorizontalDatePicker";
import { parseDate } from "main/javascripts/utils/DateUtil";
import { space } from "main/javascripts/styles/base/spaceStyle";
import { useSelector } from "react-redux";
import { IColorStyle, Label } from "../form/Label";
import {
  IBorderColorStyle,
  IFontSizeStyle,
} from "main/javascripts/styles/base/formStyle";
import { MinDepartureDateInput } from "./form/MinDepartureDateInput";
import { FormNamespaces } from "main/javascripts/constants/FormConstants";
import { MaxDepartureDateInput } from "./form/MaxDepartureDateInput";
import { DateTypes } from "main/javascripts/constants/DateTypes";
import { MAX_CRUISE_DEPARTURE_DATE_RANGE_DAYS } from "main/javascripts/constants/CruiseConstants";
import {
  fontSize,
  lineHeight,
} from "main/javascripts/styles/base/typographyStyle";
import { moreThanBreakpoint } from "main/javascripts/styles/base/responsiveStyle";
import { textColor } from "main/javascripts/styles/base/colorStyle";

export interface IProps {
  labelColorStyleKey?: keyof IColorStyle;
  borderColorStyleKey?: keyof IBorderColorStyle;
  fontSizeStyleKey?: keyof IFontSizeStyle;
  disableStopBodyScrolling?: boolean;
  onChange?: any;
}

export const CruiseDepartureDatePickerBlock: React.FC<IProps> = (
  props: IProps
): React.ReactElement => {
  const {
    labelColorStyleKey = "default",
    borderColorStyleKey,
    fontSizeStyleKey,
    disableStopBodyScrolling,
    onChange,
  } = props;
  const { t } = useTranslation(["component"]);

  // TODO: 旧reducerをそのまま使用
  const breakpoint: any = useSelector<any>(
    (state) => state.breakpointReducer.breakpoint
  );

  const { setValue, control, trigger } = useFormContext();
  const [minDepartureDateValue, maxDepartureDateValue] = useWatch({
    control,
    name: ["min_departure_date", "max_departure_date"],
  });

  const [displayedPicker, setDisplayedPicker] = useState(false);
  const [pickerDateType, setPickerDateType] = useState(null);

  const inputMinDepartureDateRef = useRef<any>();
  const inputMaxDepartureDateRef = useRef<any>();

  const onClickMinDepartureDate: () => void = () => {
    setDisplayedPicker(true);
    setPickerDateType(DateTypes.fromDate);
  };

  const onClickMaxDepartureDate: () => void = () => {
    setDisplayedPicker(true);
    setPickerDateType(DateTypes.toDate);
  };

  const onComplete: (startDate: Date | null, endDate: Date | null) => void = (
    startDate: Date | null,
    endDate: Date | null
  ) => {
    let minDepartureDateString = "";
    let maxDepartureDateString = "";
    if (startDate) {
      minDepartureDateString = format(startDate, "yyyy/LL/dd");
      setValue("min_departure_date", minDepartureDateString);
      void trigger(["min_departure_date"]);
    }
    if (endDate) {
      maxDepartureDateString = format(endDate, "yyyy/LL/dd");
      setValue("max_departure_date", maxDepartureDateString);
      void trigger(["max_departure_date"]);
    }
    if (onChange) {
      onChange(minDepartureDateString, maxDepartureDateString);
    }
    hidePicker();
  };

  const onUpdate: (
    startDate: Date | null,
    endDate: Date | null,
    isEndStep: boolean
  ) => void = (
    startDate: Date | null,
    endDate: Date | null,
    isEndStep: boolean
  ) => {
    let minDepartureDateString = "";
    let maxDepartureDateString = "";
    if (startDate) {
      minDepartureDateString = format(startDate, "yyyy/LL/dd");
      setValue("min_departure_date", minDepartureDateString);
      void trigger(["min_departure_date"]);
    }
    if (endDate) {
      maxDepartureDateString = format(endDate, "yyyy/LL/dd");
      setValue("max_departure_date", maxDepartureDateString);
      void trigger(["max_departure_date"]);
    }
    if (!isEndStep) {
      setPickerDateType(DateTypes.toDate);
    }
    if (onChange) {
      onChange(minDepartureDateString, maxDepartureDateString);
    }
  };

  const hidePicker: () => void = () => {
    setDisplayedPicker(false);
    setPickerDateType(null);
  };

  const fromDate: Date | null = minDepartureDateValue
    ? parseDate(minDepartureDateValue)
    : null;
  const toDate: Date | null = maxDepartureDateValue
    ? parseDate(maxDepartureDateValue)
    : null;

  return (
    <div css={blockStyle}>
      <div css={labelBlockStyle}>
        <Label
          namespace={FormNamespaces.cruise}
          label="departureDate"
          colorStyleKey={labelColorStyleKey}
        />
      </div>
      <div css={inputBlockStyle}>
        <MinDepartureDateInput
          namespace={FormNamespaces.cruise}
          isActive={pickerDateType === DateTypes.fromDate}
          onClick={onClickMinDepartureDate}
          inputBlockRef={inputMinDepartureDateRef}
          // labelColorStyleKey={labelColorStyleKey}
          borderColorStyleKey={borderColorStyleKey}
          fontSizeStyleKey={fontSizeStyleKey}
        />
        <div css={delimiterStyle}>&mdash;</div>
        <MaxDepartureDateInput
          namespace={FormNamespaces.cruise}
          isActive={pickerDateType === DateTypes.toDate}
          onClick={onClickMaxDepartureDate}
          inputBlockRef={inputMaxDepartureDateRef}
          // labelColorStyleKey={labelColorStyleKey}
          borderColorStyleKey={borderColorStyleKey}
          fontSizeStyleKey={fontSizeStyleKey}
        />
      </div>
      {isPhone(breakpoint) ? (
        <FullScreenModalDialog
          displayed={displayedPicker}
          closeHandler={hidePicker}
          colorMode="light"
          disableStopBodyScrolling={disableStopBodyScrolling}
        >
          <ScrollableDatePicker
            fromDate={fromDate}
            toDate={toDate}
            onComplete={onComplete}
            numberOfMonths={12}
            maxDays={MAX_CRUISE_DEPARTURE_DATE_RANGE_DAYS}
            outOfRangeMessage={t("search.calendarMaxDaysMessage")}
            startDateLabel={t("label:common.departureDate")}
            endDateLabel={t("label:common.returnDate")}
            dateType={pickerDateType}
          />
        </FullScreenModalDialog>
      ) : (
        <HorizontalDatePicker
          fromDate={fromDate}
          toDate={toDate}
          onUpdate={onUpdate}
          hidePicker={hidePicker}
          numberOfMonths={12}
          maxDays={MAX_CRUISE_DEPARTURE_DATE_RANGE_DAYS}
          outOfRangeMessage={t("search.calendarMaxDaysMessage")}
          startDateLabel={t("label:common.departureDate")}
          endDateLabel={t("label:common.returnDate")}
          displayedPicker={displayedPicker}
          dateType={pickerDateType}
          fromInputRef={inputMinDepartureDateRef.current}
          toInputRef={inputMaxDepartureDateRef.current}
          alignmentStyleKey="left"
        />
      )}
    </div>
  );
};

const blockStyle = css`
  position: relative;
  flex: 1;
  padding: ${space.atom} 0;
`;
const inputBlockStyle = css`
  display: flex;
`;
const labelBlockStyle = css`
  padding: calc(${space.atom} - (${lineHeight.body1} - ${fontSize.body}) / 2)
    ${space.atom} 0;
  margin-bottom: calc(
    (${space.atom} - (${lineHeight.body1} - ${fontSize.body}) / 2) * -1
  );
`;
const delimiterStyle = css`
  display: flex;
  align-items: center;
  height: 40px;
  padding: calc(${space.atom} - (${lineHeight.body1} - ${fontSize.body}) / 2) 0
    0;
  color: ${textColor.secondaryDarkColor};
  ${moreThanBreakpoint("tablet")} {
    padding-left: ${space.atom};
    padding-right: ${space.atom};
  }
`;
