import * as React from "react";
import { css } from "@emotion/react";
import { space } from "main/javascripts/styles/base/spaceStyle";
import { borderRadius } from "main/javascripts/styles/base/borderStyle";
import {
  accentColor,
  backgroundColor,
  borderColor,
  textColor,
} from "main/javascripts/styles/base/colorStyle";
import {
  fontSize,
  fontWeight,
  lineHeight,
} from "main/javascripts/styles/base/typographyStyle";

interface IProps {
  text: string;
  value: string;
  onRemoveClick: any;
  styleKey?: keyof IStyle;
}

export const MultipleSelectTag: React.FC<IProps> = (
  props: IProps
): React.ReactElement<IProps> => {
  const { text, value, onRemoveClick, styleKey = "default" } = props;

  const onClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    onRemoveClick(value);
  };
  return (
    <div css={blockStyle[styleKey]}>
      <div css={textStyle}>{text}</div>
      <div css={removeButtonStyle[styleKey]} onClick={onClick} />
    </div>
  );
};

export interface IStyle {
  default: any;
  defaultDark: any;
  small: any;
}

const blockBaseStyle = css`
  position: relative;
  display: flex;
  align-items: center;
  font-weight: ${fontWeight.regular};
  color: ${textColor.primaryDarkColor};
  background-color: ${borderColor.primaryLightColor};
  border-radius: ${borderRadius.normal};
  box-sizing: border-box;
  //margin: ${space.atom0_5x} ${space.atom} ${space.atom0_5x} 0;
  appearance: none;
  border-radius: ${borderRadius.normal};
  max-width: 100%;
  &:focus {
    outline: none;
  }
  &:disabled {
    background-color: transparent;
    color: ${textColor.secondaryDarkColor};
    cursor: not-allowed;
    opacity: 1;
  }
`;

const blockStyle: IStyle = {
  default: css`
    ${blockBaseStyle};
    font-size: ${fontSize.body};
    line-height: ${lineHeight.body1};
    padding: 0 2rem 0 0;
  `,
  defaultDark: css`
    ${blockBaseStyle};
    font-size: ${fontSize.body};
    line-height: ${lineHeight.body1};
    padding: ${space.atom0_5x} 0 ${space.atom0_5x} ${space.atom1_5x};
    background-color: ${textColor.primaryDarkColor};
    color: ${backgroundColor.primaryLightColor};
  `,
  small: css`
    ${blockBaseStyle};
    font-size: ${fontSize.caption};
    line-height: ${lineHeight.body2};
    padding: 0 0 0 ${space.atom1_5x};
  `,
};
const textStyle = css`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: ${space.atom0_5x} ${space.atom0_5x} ${space.atom0_5x} ${space.atom};
`;
const removeButtonBaseStyle = css`
  position: absolute;
  top: 50%;
  right: 0;
  width: 2rem;
  height: 100%;
  min-height: 2rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  transform: translateY(-50%);
  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    width: 1rem;
    height: 1px;
    background-color: ${textColor.disabledColor};
  }
  &::before {
    transform: translate(-50%, -50%) rotate(45deg);
  }
  &::after {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
  &:hover {
    &::before,
    &::after {
      background-color: ${accentColor.primaryColor};
    }
  }
`;
const removeButtonLightStyle = css`
  ${removeButtonBaseStyle};
  &::before,
  &::after {
    background-color: ${backgroundColor.primaryLightColor};
  }
  &:hover {
    &::before,
    &::after {
      background-color: ${accentColor.primaryColor};
    }
  }
`;
const removeButtonStyle: IStyle = {
  default: css`
    ${removeButtonBaseStyle};
  `,
  defaultDark: css`
    ${removeButtonLightStyle};
  `,
  small: css`
    ${removeButtonBaseStyle};
  `,
};
