import React from "react";
import { CalendarDateBlock } from "main/javascripts/components/form/CalendarDateBlock";
// import { IColorStyle } from "main/javascripts/components/form/Label";
import {
  IBorderColorStyle,
  IFontSizeStyle,
} from "main/javascripts/styles/base/formStyle";
import { get, useFormContext, useWatch } from "react-hook-form";

export interface IProps {
  namespace: string;
  name?: string;
  isActive: boolean;
  onClick(): void;
  // labelColorStyleKey?: keyof IColorStyle;
  borderColorStyleKey?: keyof IBorderColorStyle;
  fontSizeStyleKey?: keyof IFontSizeStyle;
  inputBlockRef?: any;
}

export const MaxDepartureDateInput: React.FC<IProps> = (
  props: IProps
): React.ReactElement => {
  const {
    namespace,
    name,
    isActive,
    onClick,
    // labelColorStyleKey,
    borderColorStyleKey,
    fontSizeStyleKey,
    inputBlockRef,
  } = props;

  const fieldName = name || "max_departure_date";

  const {
    control,
    formState: { errors },
  } = useFormContext();
  const value = useWatch({ control, name: fieldName });

  const attrs: any = {
    name: fieldName,
    namespace: namespace,
    // label: {
    //   namespace: namespace,
    //   label: "maxDepartureDate",
    //   colorStyleKey: labelColorStyleKey,
    // },
    date: value,
    onClick: onClick,
    isActive: isActive,
    error: get(errors, fieldName),
    inputBlockRef: inputBlockRef,
    borderColorStyleKey: borderColorStyleKey,
    fontSizeStyleKey: fontSizeStyleKey,
  };

  return <CalendarDateBlock {...attrs} />;
};
