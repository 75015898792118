import React from "react";
import { css } from "@emotion/react";
import { useFormContext, useWatch } from "react-hook-form";
import { space } from "main/javascripts/styles/base/spaceStyle";
import { IColorStyle } from "main/javascripts/components/form/Label";
import {
  IBorderColorStyle,
  IFontSizeStyle,
} from "main/javascripts/styles/base/formStyle";
import { Suggestion } from "../../types/suggestion";
import { VesselBrandInputBlock } from "./VesselBrandInputBlock";
import { MultipleSelectTag } from "../form/multipleSelect/MultipleSelectTag";
import { debouncedFetchVesselBrandSuggestions } from "../../features/cruise/cruiseSuggestionSlice";
import { useAppDispatch } from "../../store";

export interface IProps {
  defaultVesselBrands?: Suggestion[];
  suggestOptions?: any;
  labelColorStyleKey?: keyof IColorStyle;
  borderColorStyleKey?: keyof IBorderColorStyle;
  fontSizeStyleKey?: keyof IFontSizeStyle;
  disableStopBodyScrolling?: boolean;
}

export const VesselBrandBlock: React.FC<IProps> = (
  props: IProps
): React.ReactElement => {
  const {
    defaultVesselBrands,
    suggestOptions,
    labelColorStyleKey,
    borderColorStyleKey,
    fontSizeStyleKey,
    disableStopBodyScrolling,
  } = props;
  const appDispatch = useAppDispatch();

  const { setValue, control } = useFormContext();
  const namesFieldName = "vessel_brand_names";
  const idsFieldName = "vessel_brand_id"; // 単数
  const destinationIdsFieldName = "origin_destination_region_ids";
  const idValues =
    useWatch({
      control,
      name: idsFieldName,
    }) || [];
  const nameValues =
    useWatch({
      control,
      name: namesFieldName,
    }) || [];
  const destinationIdValues =
    useWatch({
      control,
      name: destinationIdsFieldName,
    }) || [];

  const onRemoveClick = (value) => {
    const targetIndex = idValues.findIndex((id) => id === value);
    if (targetIndex >= 0) {
      const newIds = idValues.filter((_, index) => index !== targetIndex);
      const newNames = nameValues.filter((_, index) => index !== targetIndex);
      setValue(idsFieldName, newIds);
      setValue(namesFieldName, newNames);
    }
  };

  const fetchVesselBrandSuggestionsAction = (data: any) => {
    const { text, ...rest } = data;
    appDispatch(
      debouncedFetchVesselBrandSuggestions({
        params: {
          text: text,
          ...rest,
          origin_destination_region_ids: destinationIdValues,
        },
      })
    );
  };

  return (
    <div css={blockStyle}>
      <VesselBrandInputBlock
        fetchVesselBrandSuggestions={fetchVesselBrandSuggestionsAction}
        labelColorStyleKey={labelColorStyleKey}
        suggestOptions={suggestOptions}
        defaultVesselBrands={defaultVesselBrands}
        borderColorStyleKey={borderColorStyleKey}
        fontSizeStyleKey={fontSizeStyleKey}
        disableStopBodyScrolling={disableStopBodyScrolling}
      />
      <div css={selectTagBlockStyle}>
        {idValues.map((id, index) => {
          return (
            <MultipleSelectTag
              key={id}
              text={nameValues[index]}
              value={id}
              onRemoveClick={onRemoveClick}
            />
          );
        })}
      </div>
    </div>
  );
};

const blockStyle = css`
  padding: ${space.atom} 0 0;
  flex: 1;
`;
const selectTagBlockStyle = css`
  display: flex;
  flex-wrap: wrap;
  gap: ${space.atom};
  padding: 0 ${space.atom} ${space.atom};
`;
