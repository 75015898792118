import React from "react";
import { css } from "@emotion/react";
import { useTranslation } from "react-i18next";
import { get, useFormContext, useWatch } from "react-hook-form";
import { SelectBlock } from "main/javascripts/components/form/SelectBlock";
import { IColorStyle, Label } from "main/javascripts/components/form/Label";
import {
  IBorderColorStyle,
  IFontSizeStyle,
} from "main/javascripts/styles/base/formStyle";
import { FormNamespaces } from "main/javascripts/constants/FormConstants";
import { MAX_CRUISE_STAYS } from "main/javascripts/constants/CruiseConstants";
import { space } from "main/javascripts/styles/base/spaceStyle";
import { fontSize, lineHeight } from "../../../styles/base/typographyStyle";
import { textColor } from "../../../styles/base/colorStyle";
import { moreThanBreakpoint } from "../../../styles/base/responsiveStyle";

export interface IProps extends React.AllHTMLAttributes<HTMLDivElement> {
  labelColorStyleKey?: keyof IColorStyle;
  borderColorStyleKey?: keyof IBorderColorStyle;
  fontSizeStyleKey?: keyof IFontSizeStyle;
}

export const StaysBlock: React.FC<IProps> = (
  props: IProps
): React.ReactElement => {
  const {
    labelColorStyleKey = "default",
    borderColorStyleKey = "none",
    fontSizeStyleKey = "default",
  } = props;
  const { t } = useTranslation(["label"]);

  const minStaysFieldName = "min_stays";
  const maxStaysFieldName = "max_stays";

  const {
    control,
    formState: { errors },
  } = useFormContext();
  const minStaysValue = useWatch({ control, name: minStaysFieldName });
  const maxStaysValue = useWatch({ control, name: maxStaysFieldName });

  const isNumeric = (value: any): boolean =>
    value !== "" && !isNaN(Number(value));

  const renderMinStays = (): React.ReactElement => {
    const maxStaysLimit = isNumeric(maxStaysValue)
      ? Number(maxStaysValue)
      : MAX_CRUISE_STAYS;
    const range = Array.from({ length: maxStaysLimit }, (_, i) => i + 1);
    const optionList = [
      <option key="-" value="">
        {t("common.unspecified")}
      </option>,
      ...range.map((value) => (
        <option key={value} value={value}>
          {value}
        </option>
      )),
    ];

    return (
      <div css={staysInputBlockStyle}>
        <SelectBlock
          namespace={FormNamespaces.cruise}
          // label={{
          //   namespace: FormNamespaces.cruise,
          //   label: "minStays",
          //   colorStyleKey: labelColorStyleKey,
          // }}
          select={{
            name: minStaysFieldName,
            children: optionList,
          }}
          error={get(errors, minStaysFieldName)}
          borderColorStyleKey={borderColorStyleKey}
          fontSizeStyleKey={fontSizeStyleKey}
        />
      </div>
    );
  };
  const renderMaxStays = (): React.ReactElement => {
    const minStaysLimit = isNumeric(minStaysValue) ? Number(minStaysValue) : 1;
    const range = Array.from(
      { length: MAX_CRUISE_STAYS - minStaysLimit + 1 },
      (_, i) => minStaysLimit + i
    );
    const optionList = [
      <option key="-" value="">
        {t("common.unspecified")}
      </option>,
      ...range.map((value) => (
        <option key={value} value={value}>
          {value}
        </option>
      )),
    ];

    return (
      <div css={staysInputBlockStyle}>
        <SelectBlock
          namespace={FormNamespaces.cruise}
          // label={{
          //   namespace: FormNamespaces.cruise,
          //   label: "maxStays",
          //   colorStyleKey: labelColorStyleKey,
          // }}
          select={{
            name: maxStaysFieldName,
            children: optionList,
          }}
          error={get(errors, maxStaysFieldName)}
          borderColorStyleKey={borderColorStyleKey}
          fontSizeStyleKey={fontSizeStyleKey}
        />
      </div>
    );
  };
  return (
    <div css={blockStyle}>
      <div css={labelBlockStyle}>
        <Label
          namespace={FormNamespaces.cruise}
          label="stays"
          colorStyleKey={labelColorStyleKey}
        />
      </div>
      <div css={inputBlockStyle}>
        {renderMinStays()}
        <div css={delimiterStyle}>&mdash;</div>
        {renderMaxStays()}
      </div>
    </div>
  );
};

const blockStyle = css`
  padding-top: ${space.atom};
`;
const inputBlockStyle = css`
  display: flex;
`;
const staysInputBlockStyle = css`
  width: 100%;
`;
const labelBlockStyle = css`
  padding: calc(${space.atom} - (${lineHeight.body1} - ${fontSize.body}) / 2)
    ${space.atom} 0;
  margin-bottom: calc(
    (${space.atom} - (${lineHeight.body1} - ${fontSize.body}) / 2) * -1
  );
`;
const delimiterStyle = css`
  display: flex;
  align-items: center;
  height: 40px;
  padding: calc(${space.atom} - (${lineHeight.body1} - ${fontSize.body}) / 2) 0
    0;
  color: ${textColor.secondaryDarkColor};
  ${moreThanBreakpoint("tablet")} {
    padding-left: ${space.atom};
    padding-right: ${space.atom};
  }
`;
