import React, { useEffect, useRef, useState } from "react";
import { css } from "@emotion/react";
import { useTranslation } from "react-i18next";
import { get, useFormContext, useWatch } from "react-hook-form";
import { InputAutosuggestBlock } from "main/javascripts/components/form/InputAutosuggestBlock";
import { useAppSelector } from "main/javascripts/store";
import {
  cruiseDestinationSuggestionsSelector,
  loadingSelector,
} from "main/javascripts/features/cruise/cruiseSuggestionSlice";
import { IColorStyle } from "main/javascripts/components/form/Label";
import { InputHidden } from "../form/InputHidden";
import { useSelector } from "react-redux";
import { isPhone } from "main/javascripts/styles/base/responsiveStyle";
import { SuggestionPicker } from "../suggestionPicker/SuggestionPicker";
import { FullScreenModalDialog } from "../molecules/Modal/FullScreenModalDialog";
import { SuggestionFieldButton } from "../suggestionPicker/SuggestionFieldButton";
import {
  fontSize,
  letterSpacing,
} from "main/javascripts/styles/base/typographyStyle";
import {
  IBorderColorStyle,
  IFontSizeStyle,
} from "main/javascripts/styles/base/formStyle";
import { Suggestion } from "main/javascripts/types/suggestion";
import { FormNamespaces } from "main/javascripts/constants/FormConstants";
import { event } from "main/javascripts/utils/googleTagManagerUtil";
import { GoogleTagManagerEventTypes } from "main/javascripts/constants/GoogleTagManagerEventTypes";

export interface IProps {
  fetchCruiseDestinationSuggestions(data: any): void;
  defaultDestinations?: Suggestion[];
  suggestOptions?: any;
  labelColorStyleKey?: keyof IColorStyle;
  borderColorStyleKey?: keyof IBorderColorStyle;
  fontSizeStyleKey?: keyof IFontSizeStyle;
  disableStopBodyScrolling?: boolean;
}

export const CruiseDestinationInputBlock: React.FC<IProps> = (
  props: IProps
): React.ReactElement => {
  const {
    fetchCruiseDestinationSuggestions,
    defaultDestinations,
    suggestOptions,
    labelColorStyleKey,
    borderColorStyleKey,
    fontSizeStyleKey,
    disableStopBodyScrolling,
  } = props;
  const { t } = useTranslation(["label"]);

  const destinations = useAppSelector(cruiseDestinationSuggestionsSelector);
  const isLoading = useAppSelector(loadingSelector);

  // TODO: 旧reducerをそのまま使用
  const breakpoint: any = useSelector<any>(
    (state) => state.breakpointReducer.breakpoint
  );

  const {
    setValue,
    control,
    formState: { errors },
  } = useFormContext();
  const fieldName = "origin_destination_region";
  const namesFieldName = "origin_destination_region_names";
  const idsFieldName = "origin_destination_region_ids";
  const value =
    useWatch({
      control,
      name: fieldName,
    }) || "";
  const nameValues =
    useWatch({
      control,
      name: namesFieldName,
    }) || [];
  const idValues =
    useWatch({
      control,
      name: idsFieldName,
    }) || [];

  const [displayedPicker, setDisplayedPicker] = useState(false);

  const inputTimeoutRef = useRef(null);
  const valueRef = useRef(value);
  valueRef.current = value;

  const currentValue = isPhone(breakpoint) ? value : value;
  const validDefaultDestinations = (
    ...values: (any[] | undefined)[]
  ): any[] | [] =>
    values.find((value) => value !== undefined && value.length > 0) ?? [];
  const suggestions = currentValue
    ? destinations
    : validDefaultDestinations(defaultDestinations);

  useEffect(() => {
    return () => {
      clearTimeout(inputTimeoutRef.current);
    };
  }, []);

  const hidePicker: () => void = () => {
    setDisplayedPicker(false);
  };

  const onPickerButtonClick: () => void = () => {
    setDisplayedPicker(true);
  };

  const onSelected = (suggestion) => {
    setValue(fieldName, "");
    setValue(namesFieldName, [...nameValues, suggestion.name]);
    setValue(idsFieldName, [...idValues, suggestion.id]);

    event(GoogleTagManagerEventTypes.destination, {
      destination_id: suggestion.id,
      destination_type: suggestion.destinationType,
      destination: suggestion.longName,
    });

    if (displayedPicker) {
      hidePicker();
    }
  };

  const onClearInput: () => void = () => {
    clearTimeout(inputTimeoutRef.current);
    setValue(fieldName, "");
  };

  const error = get(errors, namesFieldName) || get(errors, idsFieldName);

  return (
    <div css={blockStyle}>
      <InputHidden name={idsFieldName} />
      {isPhone(breakpoint) ? (
        <>
          <SuggestionFieldButton
            namespace={FormNamespaces.cruise}
            name={fieldName}
            label={{
              namespace: FormNamespaces.cruise,
              label: t("label:common.destination"),
              colorStyleKey: labelColorStyleKey,
            }}
            longName={value}
            placeholder={t("form.placeholders.cruise.destinationPlaceholder")}
            onClick={onPickerButtonClick}
            borderColorStyleKey={borderColorStyleKey}
            fontSizeStyleKey={fontSizeStyleKey}
            error={error}
          />
          <FullScreenModalDialog
            displayed={displayedPicker}
            closeHandler={hidePicker}
            colorMode="light"
            disableStopBodyScrolling={disableStopBodyScrolling}
          >
            <div css={headerTitleStyle}>{t("cruise.destination")}</div>
            <SuggestionPicker
              name={fieldName}
              suggestions={suggestions}
              placeholder={t("form.placeholders.cruise.destinationPlaceholder")}
              isLoading={isLoading}
              suggestOptions={suggestOptions}
              onRequest={fetchCruiseDestinationSuggestions}
              onSelected={onSelected}
              onClear={onClearInput}
            />
          </FullScreenModalDialog>
        </>
      ) : (
        <InputAutosuggestBlock
          namespace={FormNamespaces.cruise}
          label={{
            namespace: FormNamespaces.cruise,
            label: t("label:common.destination"),
            colorStyleKey: labelColorStyleKey,
          }}
          input={{
            name: fieldName,
            value: value,
            type: "text",
            placeholder: t("form.placeholders.cruise.destinationPlaceholder"),
          }}
          error={error}
          onSelected={onSelected}
          fetchDestinationSuggest={fetchCruiseDestinationSuggestions}
          suggestOptions={suggestOptions}
          suggestions={suggestions}
          onClear={onClearInput}
          disableOnFocusRequest
          alwaysShowSuggestions
          borderColorStyleKey={borderColorStyleKey}
          fontSizeStyleKey={fontSizeStyleKey}
        />
      )}
    </div>
  );
};

const blockStyle = css``;
const headerTitleStyle = css`
  position: absolute;
  top: 0;
  left: 50%;
  line-height: 3rem;
  font-size: ${fontSize.mediumHeading};
  font-weight: bold;
  letter-spacing: ${letterSpacing.text};
  transform: translateX(-50%);
`;
